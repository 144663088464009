.l-navigation {
//  background-color: $white;
  display: flex;
  flex-direction: column;
  @include mq-max(md) {
    flex-direction: column-reverse;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.is-active {
    transform: translateY(0%);
    & + .l-navigation__contact {
      display: block;
      .l-navigation__Btn {
        @include mq-max(md) {
          display: block;
        }
      }
    }
  }
  &__upper {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include mq-max(md) {
      display: block;
      display: flex;

      // height: calc(100vh - #{$headerHeight-sp});
      // overflow-y: scroll;
    }
    .l-navigation__item {
      @include mq-max(md) {
        width: 50%;
        text-align: center;
        border-left: 1px solid $bdrColor;
      }
    }
    .l-navigation__anchor {
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 10px 10px 10px;
      font-size: 14px;
      font-weight: 600;
      @include mq-max(md) {
        display: block;
        padding: 15px 10px;
        background-color: $white;
      }
      &:hover {
        color: $keyColor;
      }
    }
  }
  &__lower {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    @include mq-max(md) {
      display: block;
    }
    .l-navigation__Btn {
      @include mq-max(md) {
        display: block;
      }
    }

  }
  &__item {
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $gray;
      &:last-child{
        border-bottom: 1px solid $gray;
      }
    }

    //下層メニュー
    &.has-dropdown {
      & > .l-navigation__anchor {
        position: relative;
        padding: 4px 24px 16px 10px;
        @include mq-max(md) {
          padding: 15px 10px;
        }
        &:after {
          position: absolute;
          @include fa('f078');
          right: 4px;

          @include mq-max(md) {
            @include fa('f067');
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }

      }
      &:hover {
        .l-dropDown {
          // top: $headerHeight; // headerの高さ
          top: 100%; // headerの高さ
          opacity: 1;
          visibility: visible;
          box-shadow: 0 0 10px transparentize($black, 0.9);
          @include mq-max(md) {
            top: auto;
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
          }
        }
      }
      .l-dropDown {
        position: absolute;
        top: 0; // headerの高さ - 30px
        // top: -1*$headerHeight/10; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        @include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        li {
          border-bottom: 1px solid $gray;
          @include mq-max(md) {
            border-bottom: none;
          }
          &:last-child {
            border: none;
          }
          a {
            background-color: $white;
            padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            @include mq-max(md) {
              line-height: 0;
              // height: 0;
              padding: 0;
              opacity: 0;
              transition:
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
      }
      &.is-opened {
        > .l-dropDown {
          @include mq-max(md) {
            opacity: 1;
            display: block;
            > li {
              border-bottom: 1px solid $gray;
              &:first-child {
                border-top: 1px solid $gray;
              }
              &:last-child {
                border-bottom: none;
              }
            }
            > li > a {
              opacity: 1;
              line-height: 1.5;
              // height: 54px;
              padding: 15px 10px 15px 25px;
            }
          }
        }
      }
    }
  }
  &__anchor {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: $textColor;
    padding: 4px 10px 16px;
    font-weight: 600;
    @include mq-max(md) {
      display: block;
      padding: 15px 10px;
      background-color: $white;
    }
    &:hover {
      color: $keyColor;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      margin: auto;
      background-color: transparent;
    }
    &--current {
      color: $keyColor;
      &:before {
        background-color: $keyColor;
      }
    }
    &.l-navigation__anchor {
      &--recruit {
        position: relative;
        padding-left: 22px;
        &:before {
          content: "\f007";
          font-family: $FontAwesome;
          font-size: 16px;
          position: absolute;
          top: 10px;
          left: 0;
          color: $keyColor;
          bottom: auto;
          width: auto;
          @include mq-max(md) {
            display: inline-block;
            left: auto;
            right: auto;
            top: auto;
            margin-top: -2px;
            margin-left: -20px;
          }
        }
      }
      &--news {
        position: relative;
        padding-left: 22px;
        &:before {
          content: "\f1ea";
          position: absolute;
          font-family: $FontAwesome;
          font-size: 16px;
          top: 10px;
          left: 0;
          color: $keyColor;
          bottom: auto;
          width: auto;
          @include mq-max(md) {
            display: inline-block;
            left: auto;
            right: auto;
            top: auto;
            margin-top: -2px;
            margin-left: -20px;
          }
        }

      }
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    padding-left: 25px;
    @include mq-max(md) {
      display: none;
      padding-left: 0;
    }
  }
  &__Btn {
    position: relative;
    border-radius: 7px;
    border: 2px solid $keyColor;
    background-color: $keyColor;
    color: $white;
    padding: 30px 14px 12px;
    font-size: 12px;
    font-weight: 500;
    transition: color $transition,
                background-color $transition;
                @include mq-max(md) {
                  display: none;
                  border-radius: 0;
                  padding-left: 0;
                  width: 100%;
                  text-align: center;
                  padding: 15px 10px;

                }
    &:before {
      content: '';
      position: absolute;
      @include fa('f0e0');
      font-size: 16px;
      font-weight: 400;
      top: 5px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      @include mq-max(md) {
        left: auto;
        right: auto;
        top: auto;
        margin-top: -2px;
        margin-left: -20px;
      }
    }
    &:hover {
      background-color: $white;
    }
  }
}
