.l-f {
  background-color: $white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    @include mq-max(md) {
      padding: 50px 30px;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
    > .grid {
      justify-content: space-between;
      @include mq-max(md) {
        justify-content: center;
      }
    }
  }
  &__info {
    display: block;
    margin-bottom: 35px;
    > p {
      margin-bottom: 0;
    }
    &--lower {
      margin-bottom: 0;
    }
  }
  &__logo {
    > img {
      width: auto;
    }
  }
  &__nav {
    display: flex;
    @include mq-max(md) {
      margin: 0 15px;
    }
    @include mq-max(sm) {
      flex-wrap: wrap;
    }
  }
  &__links {
      margin-left: 50px;
      &:first-child {
        margin-left: 0;
      }
      @include mq-max(sm) {
        margin-left: 0;
        width: 50%;
        margin-bottom: 30px;
      }
      @include mq-max(xs) {
      }
      & > li {
        a {
          position: relative;
          display: block;
          font-weight: 600;
          color: $textColor;
          padding: 3px 15px 3px 0;
          &:before {
            position: absolute;
            content: '';
            width: 10px;
            height: 2px;
            left: -15px;
            top: 50%;
            background-color: $keyColor;
          }
        }
      }
    }

  //Copyright
  &__copyright {
    display: block;
    background-color: $keyColor;
    color: $white;
    text-align: center;
    padding: 1z5px 0;
    font-size: 16px;
  }
}
